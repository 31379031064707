import React from 'react';
import { Row, Col, Typography } from 'antd';
import Layout from '../../components/Layout/layout';
import SEO from '../../components/seo';
import CtaSecondary from '../../components/Buttons/secondary-cta';
import ogImage from '../../images/global_assets/og-image.png';
import LegalStyles from '../../components/Layout/LegalStyles';

const { Title } = Typography;

const AccessibilityStatement = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="SpotOn is committed to ensuring all its products and digital properties conform to the Web Content Accessibility Guidelines (WCAG) 2.1, Level A and AA and comply with Americans with Disabilities Act (ADA) effective communication requirements, and other applicable regulations."
        image={`https://spoton-website-staging.netlify.app/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={1}>SpotOn Accessibility Statement</Title>

              <p>
                SpotOn is committed to ensuring all its products and digital
                properties conform to the{' '}
                <a
                  href="https://www.essentialaccessibility.com/blog/web-content-accessibility-guidelines-wcag/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Web Content Accessibility Guidelines (WCAG)
                </a>{' '}
                2.1, Level A and AA and comply with{' '}
                <a
                  href="https://www.essentialaccessibility.com/blog/ada-guidelines-compliance/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Americans with Disabilities Act (ADA)
                </a>{' '}
                effective communication requirements, and other applicable
                regulations.
              </p>
              <p>
                To accomplish this, we have partnered with eSSENTIAL
                Accessibility to administer our accessibility program and
                oversee its governance to ensure the best user experience for
                all our customers and clients. Their accessibility program
                evaluates our digital products on an ongoing basis in accordance
                with best practices and is supported by a diverse team of
                accessibility professionals, including users of assistive
                technologies. The platform, moreover, goes beyond minimum
                compliance requirements by making an{' '}
                <a
                  href="https://www.essentialaccessibility.com/spoton?utm_source=spotonhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=spoton"
                  rel="noreferrer"
                  target="_blank"
                >
                  assistive CX technology
                </a>{' '}
                application available to customers who have trouble typing,
                gesturing, moving a mouse, or reading. The application is free
                to download and it incorporates tools such as mouse and keyboard
                replacements, voice recognition, speech enablement,
                hands-free/touch-free navigation, and more.
              </p>
              <p>
                We want to hear from you if you encounter any accessibility
                barriers on our digital properties. Please contact our Customer
                Support at <a href="tel:+18778144102">(877) 814-4102</a> or{' '}
                <a href="mailto:support@spoton.com">support@spoton.com</a>
              </p>
            </div>
          </Col>

          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary 
                target="/legal/user-terms" 
                ctaTitle="User Terms" 
              />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle="Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default AccessibilityStatement;
