import React from "react";
import { viewports, colors } from "../../style-vars";

const { mdOrSmaller } = viewports;
const { black, white, primary } = colors;

const LegalStyles = () => (
  <style jsx global>{`
    .consumer-privacy__data {
      padding-left: 160px;
      margin-top: 64px;
    }

    @media ${mdOrSmaller} {
      .consumer-privacy__data {
        padding: 32px;
      }
    }
    .specialty {
      padding-top: 0 !important;
    }
    .consumer-privacy__data h2 {
      margin-bottom: 40px !important;
      font-weight: 800 !important;
      padding-top: 40px;
      margin-top: 40px !important;
    }
    .consumer-privacy__data h3 {
      font-weight: 800 !important;
      margin: 30px 0 !important;
      color: ${black} !important;
      font-size: 22px;
    }
    .consumer-privacy__data .data {
      font-size: 16px !important;
      font-weight: 800 !important;
      margin-bottom: 20px !important;
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
    .consumer-privacy__data h5 {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: ${primary};
      text-transform: uppercase;
    }
    .consumer-privacy__data p,
    li {
      color: ${black};
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 4px;
    }
    .aside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 100px;
      position: fixed !important;
      top: 10%;
      width: 300px;
      bottom: 300px;
    }
    @media ${mdOrSmaller} {
      .aside {
        display: none;
      }
    }
    .top-nav {
      background: ${white};
      padding: 15px 160px;
      height: 72px;
    }
    .top-nav button {
      display: flex;
    }
  `}</style>
);

export default LegalStyles;
